<template>
  <div>
    <FormTemplateAdd
      :initial-form-template.sync="formTemplate"
      :form-template-value.sync="formTemplate"
      :type-answer-options.sync="typeAnswerOptions" />
  </div>
</template>

<script>
import FormTemplateAdd from './components/FormTemplateAdd.vue'

export default {
  components: {
    FormTemplateAdd
  },
  data () {
    return {
      formTemplate: {
        name: '',
        question: [
          {
            title: '',
            type: '',
            maximumScore: null,
            isRequired: false
          }
        ]
      },
      typeAnswerOptions: [
        { label: 'ข้อความ', value: 'message' },
        { label: 'ตัวเลข', value: 'number' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
