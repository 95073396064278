<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="FormTemplateAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <b-row>
              <b-col
                sm="12"
                md="5">
                <TextFieldInput
                  v-model="formTemplate.name"
                  :rules="{ required: true }"
                  name="Name Template Form"
                  label="Name Template Form"
                  placeholder="Name Template Form"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <draggable
                  :list="formTemplate.question"
                  :disabled="!enabled"
                  @start="dragging = true"
                  @end="dragging = false">
                  <div
                    v-for="(questionTypeValue, index) in formTemplate.question"
                    :key="index"
                    class="shadow-sm rounded">
                    <b-card
                      border-variant="light">
                      <div
                        class="text-center">
                        <b-img
                          src="@/assets/images/icons/icon-park_application-menu.svg"
                          alt="icon-park_application-menu"></b-img>
                      </div>
                      <b-row>
                        <b-col
                          sm="12"
                          md="12">
                          <TextFieldInput
                            v-model="questionTypeValue.title"
                            :rules="{ required: true }"
                            name="คำถาม"
                            label="คำถาม"
                            placeholder="คำถาม"
                            trim />
                        </b-col>
                        <b-col
                          sm="12"
                          md="5">
                          <SelectField
                            v-model="questionTypeValue.type"
                            :options="typeAnswerOptions"
                            :rules="{ required: true }"
                            placeholder="ประเภทคำตอบ"
                            name="ประเภทคำตอบ"
                            form-label="ประเภทคำตอบ"
                            label-option="label"
                            return-value="value" />
                        </b-col>
                        <b-col
                          sm="12"
                          md="3">
                          <div
                            v-if="questionTypeValue.type === 'number'"
                          >
                            <TextFieldInput
                              v-model="questionTypeValue.maximumScore"
                              :rules="{ required: true }"
                              name="จำนวนตัวเลขไม่เกิน"
                              label="จำนวนตัวเลขไม่เกิน"
                              placeholder="จำนวนตัวเลขไม่เกิน"
                              trim />
                          </div>
                        </b-col>
                      </b-row>
                      <div class="d-flex align-items-center">
                        <div
                          class="d-flex pr-2"
                          style="padding-top: 6px;">
                          <b-form-checkbox
                            v-model="questionTypeValue.isRequired"
                            class="custom-control-success d-inline-block pr-0"
                            switch
                            size="lg">
                          </b-form-checkbox>
                          <h5
                            style="line-height: 2.2;">
                            {{ questionTypeValue.isRequired ? 'จำเป็น' : 'ไม่จำเป็น' }}
                          </h5>
                        </div>
                        <div
                          v-if="formTemplate.question.length > 1"
                          class="d-flex align-items-center">
                          <div
                            class="vl pr-1"></div>
                          <b-button
                            v-ripple.400="'rgba(200, 0, 0,)'"
                            variant="flat-danger"
                            class="btn-icon rounded-circle"
                            @click="removeQuestionType(index)"
                          >
                            <b-img
                              src="@/assets/images/icons/solar_trash-bin-2-outline.svg"
                              alt="solar_trash-bin-2-outline"></b-img>
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </draggable>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              style="height: 40px;"
              variant="outline-primary"
              class="mr-2 btn-submit"
              @click="addQuestionType()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
              <span class="align-middle">เพิ่มคำถาม</span>
            </b-button>
            <div class="pt-3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class=""
                variant="success"
                @click="addFormTemplate()">
                <span class="align-middle"> Save </span>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
// import RadioButton from '@/components/Form/RadioButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormTemplateProvider from '@/resources/FormTemplateProvider'

const FormTemplateService = new FormTemplateProvider()

export default {
  components: {
    TextFieldInput,
    SelectField,
    draggable
    // RadioButton
  },
  props: {
    initialFormTemplate: {
      type: Object,
      default: () => ({})
    },
    typeAnswerOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      enabled: true,
      dragging: false
    }
  },
  computed: {
    formTemplate: {
      get () {
        return this.initialFormTemplate
      },
      set () {
        this.$emit('update:form-template-value')
      }
    }
  },
  methods: {
    resetForm () {
      this.$refs.FormTemplateAddForm.reset()
    },
    async addFormTemplate () {
      try {
        const payload = { ...this.formTemplate }
        await FormTemplateService.create(payload)
        this.$router.push({ name: 'form-template' })
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Form Template has been void'
          }
        })
      } catch (error) {
        console.log('error', error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Form Template has been Failed'
          }
        })
      }
    },
    addQuestionType () {
      this.formTemplate.question.push({
        title: '',
        type: '',
        maximumScore: null,
        isRequired: false
      })
    },
    removeQuestionType (index) {
      this.formTemplate.question.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.vl {
  border-left: 1px solid black;
  height: 40px;
}
.btn--delete {
  cursor: pointer;
}

div::v-deep .custom-control-label {
  padding-left: 0px;
}

</style>
